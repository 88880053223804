*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Custom Properties, update these for your own design */

:root {
    /* --ff-alt: 'Rubik Moonrocks', cursive; */

    --ff-primary: 'Source Sans Pro', sans-serif;
    --ff-secondary: 'Source Code Pro', monospace;

    --fw-reg: 300;
    --fw-bold: 900;



    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;

    --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, .25),
        0.125em 0.125em 0.25em rgba(0, 0, 0, .15);
}

#light {

    --clr-light: #eeeeee;
    --clr-dark: #312f2f;
    --clr-accent: #9e91d2;
}


#dark {

    --clr-light: #212f45;
    --clr-dark: #f26e3e;
    --clr-accent: #086375;
}

#dark {
    background: var(--clr-light);
    color: var(--clr-dark);
}

#light {
    background: var(--clr-light);
    color: var(--clr-dark);
}

body {
    margin: 0;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    line-height: 1.6;
}

@media (min-width: 800px) {
    :root {
        --fs-h1: 4.5rem;
        --fs-h2: 3.75rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
    }
}

/* General styles */

/* html{
    scroll-behavior: smooth;
} */

/* particles.js start */

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #111;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

/* particles.js end */




section {
    padding: 5em 2em;
}

img {
    display: block;
    max-width: 100%;
}

strong {
    font-weight: var(--fw-bold)
}

:focus {
    outline: 3px solid var(--clr-accent);
    outline-offset: 3px;
}

/* Buttons */
.darkmode {
    left: 20px;
}

.btn {
    display: inline-block;
    padding: .5em 2.5em;
    background: var(--clr-accent);
    color: var(--clr-dark);
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: var(--fw-bold);
    transition: transform 200ms ease-in-out;
}

.btn:hover {
    transform: scale(1.1);
}

/* Typography */

h1,
h2,
h3 {
    line-height: 1;
    margin: 0;
}

h1 {
    font-size: var(--fs-h1)
}

h2 {
    font-size: var(--fs-h2)
}

h3 {
    font-size: var(--fs-h3)
}


.section__title {
    margin-bottom: .25em;
}

.section__title--intro {
    font-weight: var(--fw-reg);
}

.section__title--intro strong {
    display: block;
}

.section__subtitle {
    margin: 0;
    font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
    background: var(--clr-accent);
    padding: .25em 1em;
    font-family: var(--ff-secondary);
    margin-bottom: 1em;
}

.section__subtitle--work {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    margin-bottom: 2em;
}

/* header */

.logo {
    max-width: 230px;
    margin-left: 4.2rem;
    margin-top: .4rem;
}

header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.nav {
    position: fixed;
    background: var(--clr-dark);
    color: var(--clr-light);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    transform: translateX(0%);
    transition: transform 250ms cubic-bezier(.5, 0, .5, 1);

}

.nav-closed {

    transform: translateX(100%);

}

.nav__list {
    list-style: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 0;
}

.nav__link {
    color: inherit;
    font-weight: var(--fw-bold);
    font-size: var(--fs-h2);
    text-decoration: none;
}

.nav__link:hover {
    color: var(--clr-accent);
}

.nav-toggle {

    padding: 1em;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: fixed;
    right: 1em;
    top: 1.3em;
    z-index: 1000;
    transform: scale(1.2);

}

.nav-toggle-closed {

    cursor: pointer;
    position: absolute;

}

.nav-open .hamburger {
    transform: rotate(.625turn);
}

.nav-open .hamburger::after {
    opacity: 0;
}

.nav-open .hamburger::before {
    transform: rotate(90deg) translateX(-6px);
}


.hamburger {
    display: block;
    position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    background: var(--clr-accent);
    width: 2em;
    height: 3px;
    border-radius: 1em;
    transition: transform 250ms ease-in-out;

}

.hamburger::before {
    top: 6px;
}

.hamburger::after {
    bottom: 6px;
}


.hamburger::before,
.hamburger::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;

}


/*  Intro section  */

.cap-bold{
    text-transform: capitalize;
}
.intro-link {
    text-decoration: none; 
    display: inline-block;
    position: relative;
  }
  .intro-link:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--clr-accent);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after { 
    width: 100%; 
    left: 0; 
  }


.intro-link{
    color: inherit;
    text-decoration: none;
}

.intro {
    position: relative;
    
}

.intro__img {
    box-shadow: var(--bs);
    object-fit: contain;
}

.section__subtitle--intro {
    display: inline-block;
}

@media (min-width: 600px) {

    .intro {
        display: grid;
        width: min-content;
        margin: 0 auto;
        grid-column-gap: 1em;
        grid-template-areas:
            "img title"
            "img subtitle";
        grid-template-columns: min-content max-content;
    }

    .intro__img {
        grid-area: img;
        min-width: 250px;
        position: relative;
        z-index: 2;
    }

    .section__subtitle--intro {
        align-self: start;
        grid-column: -1 / 1;
        grid-row: 2;
        text-align: right;
        position: relative;
        left: -1.5em;
        width: calc(100% + 1.5em);
    }

}
@media (max-width: 400px) {
    #logo{
        max-width: 12rem;
        max-height: 12rem;
        margin-top: .2rem;
    }
}



/*  My services section  */

.skill-logo {
    font-size: 4.3rem;
}

.skill-head {
    color: var(--clr-accent);
}

.my-services {
    background-color: var(--clr-dark);
    /* background-image: url(../images/services-bg.jpg); */
    background-size: cover;
    /* background-blend-mode: multiply; */
    color: var(--clr-light);
    text-align: center;
    
}

.section__title--services {
    color: var(--clr-accent);
    position: relative;
}

.section__title--services::after {
    content: '';
    display: block;
    width: 2em;
    height: 1px;
    margin: 0.5em auto 1em;
    background: var(--clr-light);
    opacity: 0.25;
}

.services {
    margin-bottom: 4em;
}

.service {
    max-width: 500px;
    margin: 0 auto;
}

@media (min-width: 800px) {
    .logo {
        max-width: 250px;
    }
    .services {
        display: flex;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    .service+.service {
        margin-left: 2em;
    }
}


.about-me {
    max-width: 1000px;
    margin: 0 auto;
}

.about-me__img {
    box-shadow: var(--bs);
}

@media (min-width: 600px) {
    .logo {
        max-width: 300px;
        margin-top: .05rem;
    }
    .about-me {
        display: grid;
        grid-template-columns: 1fr 200px;
        grid-template-areas:
            "title img"
            "subtitle img"
            "text img";
        grid-column-gap: 2em;
    }

    .section__title--about {
        grid-area: title;
    }

    .section__subtitle--about {
        grid-column: 1 / -1;
        grid-row: 2;
        position: relative;
        left: -1em;
        width: calc(100% + 2em);
        padding-left: 1em;
        padding-right: calc(200px + 4em);
    }

    .about-me__img {
        grid-area: img;
        position: relative;
        z-index: 2;
    }
}

/* My Work */

.my-work {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    text-align: center;
}

.portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.portfolio__item {
    background: var(--clr-accent);
    overflow: hidden;
}

.portfolio__img {
    transition:
        transform 750ms cubic-bezier(.5, 0, .5, 1),
        opacity 250ms linear;
}

.portfolio__item:focus {
    position: relative;
    z-index: 2;
}

.portfolio__img:hover,
.portfolio__item:focus .portfolio__img {
    transform: scale(1.2);
    opacity: .5;
}


/* footer */

.footer {
    background: #111;
    color: var(--clr-accent);
    text-align: center;
    padding: .5em 2.5em .1em 2.5em;
    font-size: var(--fs-h3);

}

.footer a {
    color: inherit;
    text-decoration: none;
}

.social-list__link:hover {
    opacity: .7;
}

.social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0em 0 0;
    padding: 0;
    
}

.social-list__item {
    margin: 0 .7em;
}

.social-list__link {
    padding: .5em;
}

.portfolio-item-individual {
    padding: 0 2em 2em;
    max-width: 1000px;
    margin: 0 auto;
}

.portfolio-item-individual p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}



.pixel-art {
    max-width: 4em;
    max-height: 4em;
    margin-left: auto;
    margin-right: auto;
    transition: 0.1s all ease-in-out;
}

.pixel-art:hover {
    transform: scale(1.1);
}
.footer-end{
    
    color: #f5cb5c;
    font-size: 1.2rem;
    top: 5px
}
.footer-end1{
    color: #a6808c;
    position: relative;
    bottom: .65em;
    
}
.footer-icon{
    font-size: 2em;
}
.clickme{
    max-width: 15em;
    max-height: 15em;
    margin-left: auto;
    margin-right: auto;
}

/* theme_button */

.onoffswitch {
    position: absolute;
    width: 55px;
    margin-top: .65rem;
}

.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 30px;
    padding: 0;
    line-height: 30px;
    border: 0px solid #FFFFFF;
    border-radius: 35px;
    background-color: #9E91D2;
}

.onoffswitch-label:before {
    content: "";
    display: block;
    width: 35px;
    margin: -2.5px;
    background: #312F2F;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 26px;
    border-radius: 35px;
    box-shadow: 0 6px 12px 0px #757575;
}

.onoffswitch-checkbox:checked+.onoffswitch-label {
    background-color: #F26E3E;
}

.onoffswitch-checkbox:checked+.onoffswitch-label,
.onoffswitch-checkbox:checked+.onoffswitch-label:before {
    border-color: #F26E3E;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label:before {
    right: 0px;
    background-color: #086375;
    box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
}

/* footer arrow */